<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 17:38:48
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-18 11:12:32
-->
<template>
  <div class="box">
    <div class="bgs"></div>
    <div class="content">
      <img src="@/assets/images/woman.png" alt="" class="woman">
      <img src="@/assets/images/topImg.png" alt="" class="topImg">
      <menus @newTap="newTap" v-if="type == 1"></menus>
      <menus2 @newTap="newTap" v-else></menus2>
      <slot></slot>
    </div>
    <body-right></body-right>
    <countDown v-if="flag"></countDown>
  </div>
</template>

<script>
import bodyRight from '@/components/bodyRight.vue';
import countDown from '@/components/countDown.vue';
import menus from '@/components/menu.vue';
import menus2 from '@/components/menu2.vue';
export default{
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  components: { bodyRight, countDown, menus, menus2 },
  data(){
    return{
      flag: false
    }
  },
  mounted() {
    if(this.$route.path == '/newDetail'){
      this.flag = false;
    }else{
      this.flag = true;
    }
  },
  methods: {
    newTap(value) {
      this.$emit('click', value);
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  .bgs {
    // position: fixed;
    position: absolute;
    left: 0;
    // top: 80px;
    top: 0;
    width: 100%;
    height: 960px;
    background-image: linear-gradient(to bottom, #FF2F07, #fff);
  }  
  .content {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 150px;
    position: relative;
    z-index: 1;
    .topImg{
      height: 74px;
      position: absolute;
      left: 50%;
      top: 60px;
      transform: translateX(-50%);
    }
    .woman {
      height: 149px;
      position: absolute;
      left: 20px;
      top: 46px;
    }
  }
}
</style>