<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 17:08:59
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-04 00:28:29
-->
<template>
  <div class="cont-down">
    <div class="logos">
      <img src="@/assets/images/yang.png" alt="">
    </div>
    <div class="count">
      <div class="count-down-box">
        <h3 class="count-down-title">金小央提醒您距离考研还有</h3>
        <div class="clock-con">
          <div class="time-item">
            <div class="time-item-bg"></div>
            <div class="time-item-num">{{timeOut[0]}}</div>
          </div>
          <div class="time-item">
            <div class="time-item-bg"></div>
            <div class="time-item-num">{{timeOut[1]}}</div>
          </div>
          <div class="time-item">
            <div class="time-item-bg"></div>
            <div class="time-item-num">{{timeOut[2]}}</div>
          </div>
          <div class="time-item">
            <div class=""></div>
            <div class="time-item-num">天</div>
          </div>
        </div>
      </div>
      <div class="deadline-box">
        截止时间:
        <span class="deadline-str-box">{{time}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/util/http'
export default {
  data() {
    return {
      time: '',
      timeOut:[0,0,0]
    };
  },
  mounted() {
    this.timeData();
  },
  methods: {
    async timeData() {
      var { data } = await http.get('/api/index/getYan');
      this.time = data._date;
      var that = this;
      setInterval(() => {
        let timeOut = that.countDown(data._date).d.toString().split('');
        if (timeOut.length === 2) {
          this.timeOut = [0].concat(timeOut)
        } else if (timeOut.length === 1) {
          this.timeOut = [0, 0].concat(timeOut)
        } else if (timeOut.length === 3){
          this.timeOut = timeOut
        } else {
          this.timeOut = [0, 0, 0]
        }
      }, 1000)
    },
    countDown(endTime) {
      var nowtime = new Date();  //获取当前时间
      var endtime = new Date(endTime);  //定义结束时间
      var lefttime = endtime.getTime() - nowtime.getTime();  //距离结束时间的毫秒数
      var leftd = Math.floor(lefttime/(1000*60*60*24)) + 1;  //计算天数
      var lefth = Math.floor(lefttime/(1000*60*60)%24);  //计算小时数
      var leftm = Math.floor(lefttime/(1000*60)%60);  //计算分钟数
      var lefts = Math.floor(lefttime/1000%60);  //计算秒数
      return {
        d: leftd,
        h: lefth,
        m: leftm,
        s: lefts
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.cont-down {
  position: fixed;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  z-index: 8889;

  .logos {
    width: 100%;
    position: relative;

    img {
      width: 158px;
      position: absolute;
      left: 50%;
      top: -125px;
      transform: translateX(-50%);
    }
  }
}

.count {
  background-color: #FFF;
  border-radius: 10px;
  position: relative;
  z-index: 888;
  padding: 15px;
  padding-bottom: 10px;
}

.count-down-box {
  width: 250px;
  height: 150px;
  background-color: #f74730;
  margin: 0 auto;
  text-align: center;
}

.count-down-title {
  padding-top: 10px;
  font-weight: bold;
  color: #fff;
}

.clock-con {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 80px;
  margin: 0 auto;
  margin-top: 20px;
}

.time-item {
  position: relative;
  width: 22%;
  height: 100%;
  // &:first-child{
  //   width: 40%;
  // }
}

.time-item-num {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  font-size: 40px;
  line-height: 80px;
  font-weight: bold;
  color: white;
}

.time-item-bg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.time-item-bg::before,
.time-item-bg::after {
  content: "";
  width: 100%;
  height: 48%;
  background-color: rgba(0, 0, 0, .8);
}

.deadline-box {
  width: 250px;
  /* height: 30px; */
  margin: 0 auto;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}
</style>
