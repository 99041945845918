<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 11:45:53
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-07 08:45:29
-->
<template>
  <div class="menu">
    <div class="item" :class="{ active: active == index }" v-for="(item, index) in menu" :key="index"
      @click.stop="goRoute(item)">
      {{ item.name }}
      <template v-if="item.children && item.children.length">
        <div class="child">
          <a @click.stop="tap(d, idx, index)" class="cell" v-for="(d, idx) in item.children" :key="idx">{{d.name}}</a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      active: 2,
      menu: [
        { name: '首页', url: 'indexs' },
        { name: '课程', url: 'live', children: [] },
        { name: '资讯', url: 'new', children: [] },
        { name: '题库', url: 'home' },
        { name: '硕士招生', url: 'student' },
        { name: '资料下载', url: 'down', children: [] },
        { name: '考研小程序', url: 'wxapp' },
        { name: '报名咨询', url: 'advisory' }
      ],
    };
  },
  computed: {
    ...mapState(['category', 'pcategory', 'dcategory'])
  },
  mounted() {
    setTimeout(()=> {
      this.menu[1].children = this.pcategory;
      var cate = [];
      this.category.forEach(o => cate.push(o))
      
      // cate.shift()
      this.menu[2].children = cate;
      this.menu[5].children = this.dcategory;
    }, 200)
    this.active = this.menu.findIndex(o => o.name == this.$route.meta.title);
  },
  methods: {
    tap(item, idx, index){
      if(index == 2){
        if(this.$route.name == 'new'){
          this.$emit('newTap', idx);
        }
        this.$router.push('/new?cid='+idx)
      }else if(index == 5){
        this.$router.push('/down?cid='+idx)
      }else{
        this.$router.push('/live?cid='+idx)
        if(this.$route.name == 'live'){
          window.location.reload(true);
        }
      }
    },
    goRoute(item) {
      if (!item.url) return;
      if(item.url == 'live' && !this.$store.getters.uniqid) {
        return this.$root.Bus.$emit('showLogin');
      }
      if(item.url == 'home'){
        window.open('#/home', '_blank');
        return;
      }
      this.$router.push(item.url);
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  width: 100%;
  height: 60px;
  background-image: linear-gradient(to bottom, #FFCCC2, #FFF0ED);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 55;
  .item {
    flex: 1;
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    &:hover .child{
      top: 60px;
      display: block;
    }
    .child{
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      transition: all .5s;
      display: none;
      .cell{
        text-align: center;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        background: rgba(255, 106, 41, .9);
        color: #FFF;
        display: block;
        text-decoration: none;
      }
    }
    &:hover,&.active {
      background: rgba(255, 106, 41, 1);
      color: #FFF;
    }
  }
}
</style>