<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 16:08:45
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-29 10:46:11
-->
<template>
  <div class="body-right">
    <div class="item item1" @click="$router.push({name: 'advisory'})">
      <div class="cells">
        <div class="icon"><img src="@/assets/images/right1.png" alt=""></div>
        <div class="font">在线咨询</div>
      </div>
    </div>


    <el-popover placement="left" title="客服热线" width="150" trigger="hover">
      <p style="font-size: 18px; color: rgba(51, 51, 51, 1);">18211059862</p>
      <div class="item item2" slot="reference">
        <div class="cells">
          <div class="icon hover1"><img src="@/assets/images/right2.png" alt=""></div>
          <div class="icon hover2"><img src="@/assets/images/right2-1.png" alt=""></div>
          <div class="font">电话</div>
        </div>
      </div>
    </el-popover>

    <el-popover placement="left" trigger="hover">
      <div class="app">
        <div class="code"><img src="@/assets/images/appCode.png" alt=""></div>
        <div class="codeName">扫码下载APP</div>
        <div class="codeName">海量课程免费听</div>
      </div>
      <div class="item item3" slot="reference">
        <div class="cells">
          <div class="icon hover1"><img src="@/assets/images/right3.png" alt=""></div>
          <div class="icon hover2"><img src="@/assets/images/right3-1.png" alt=""></div>
          <div class="font">APP</div>
        </div>
      </div>
    </el-popover>

    <el-popover placement="left" trigger="hover">
      <div class="app">
        <div class="code"><img src="@/assets/images/weixinCode.png" alt=""></div>
        <div class="codeName">扫码关注金小央</div>
      </div>
      <div class="item item3" slot="reference">
        <div class="cells">
          <div class="icon hover1"><img src="@/assets/images/right5.png" alt=""></div>
          <div class="icon hover2"><img src="@/assets/images/right5-1.png" alt=""></div>
          <div class="font">公众号</div>
        </div>
      </div>
    </el-popover>

    <div class="item item4" @click="backTop">
      <div class="cells">
        <div class="icon"><img src="@/assets/images/right4.png" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("scroll", this.showbtn, true)
  },
  methods: {
    showbtn() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 1000) {
        // this.showBtn = true;
      } else {
        // this.showBtn = false;
      }
    },
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
}
</script>

<style lang="scss">
.app {
  .codeName {
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
  }

  .code {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    background-color: rgba(226, 226, 226, 1);
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.body-right {
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 10px 3px rgba(230, 230, 230, .4);
  z-index: 9999;

  .item {
    background-color: #FFF;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &.item4 {
      background-color: #FFF;
      height: 60px;
    }

    &.item2:hover,
    &.item3:hover {
      .hover1 {
        display: none;
      }

      .hover2 {
        display: block;
      }

      .font {
        color: rgba(255, 106, 41, 1);
      }
    }

    &.item2,
    &.item3 {
      height: 90px;
      background-color: #FFF;

      .hover1 {
        display: block;
      }

      .hover2 {
        display: none;
      }

      .font {
        color: #999999;
      }

      &::after {
        content: '';
        width: 50%;
        height: 1px;
        background-color: #E6E6E6;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.item1 {
      height: 90px;
      background-color: #FF6A29;

      .font {
        color: #FFF;
      }
    }

    .cells {
      margin: 0 auto;

      .font {
        padding-top: 5px;
        line-height: 1;
        text-align: center;
        font-size: 14px;
      }

      .icon {
        width: 35px;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>