<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 11:45:53
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-18 11:18:46
-->
<template>
  <div class="menu">
    <div class="item" :class="{ active: active == item.value }" v-for="(item, index) in menu" :key="index"
      @click.stop="goRoute(item.value)">
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 1,
      menu: [
        { label: '每日一练', value: 1 },
        { label: '考点练习', value: 2 },
        { label: '历年真题', value: 3 },
        { label: '模拟试题', value: 4 }
      ],
    };
  },
  methods: {
    goRoute(value) {
      this.active = value;
      this.$emit('newTap', value);
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  width: 100%;
  height: 60px;
  background-image: linear-gradient(to bottom, #FFCCC2, #FFF0ED);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 55;
  .item {
    flex: 1;
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    &:hover .child{
      top: 60px;
      display: block;
    }
    .child{
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      transition: all .5s;
      display: none;
      .cell{
        text-align: center;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        background: rgba(255, 106, 41, .9);
        color: #FFF;
        display: block;
        text-decoration: none;
      }
    }
    &:hover,&.active {
      background: rgba(255, 106, 41, 1);
      color: #FFF;
    }
  }
}
</style>